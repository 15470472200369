<template>
  <div>
    <Row class="p-b-5">
      <i-col span="20">
        <span class="m-t-5">档期：</span>
        <div v-for="(schedule, i) in schedules" :key="i" @click="scheduleIndex = i" class="m-b-2"
          :class="scheduleIndex === i ? 'workplatform-radio-button-active' : 'workplatform-radio-button'">
          {{ getScheduleInfo(schedule) }}</div>
      </i-col>
      <i-col span="4" class="text-right">
        <Button type="success" size="small" @click="onViewOrderMaterial">查看订单素材</Button>
      </i-col>

    </Row>

    <h4 class="workplatform-title">资源站点分布</h4>
    <p class="p-t-5 p-r-10 p-l-10" v-for="(item) in assets" :key="item.assetId">
      <span class="title">{{ item.assetName }}</span>
      <a v-for="(station, sindex) in stationDistribution.filter(x => x.assetId === item.assetId)" style="padding: 5px;"
        :key="'a' + sindex" @click="showStationSvg(station)">{{ station.stationName + '(' + station.quantity + ')' }}</a>
    </p>

    <Table class="m-t-10" :columns="FixedColumn" size="small" stripe :data="tableData"></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="resourceListQuery.pageSize" show-total show-elevator
        :current="resourceListQuery.pageNumber" @on-change="changePage"></Page>
    </div>

    <SvgStation ref="svgStation" :stationSvgModal.sync="svgModal.isShow" :orderId="orderId"
      :stationId="svgModal.stationId" :searchSchedules="[{ startDate: svgModal.startDate, endDate: svgModal.endDate }]"
      :svgShowType="svgModal.svgShowType" showStationPortray @svg-finished="svgOrThreeFinished"></SvgStation>

  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import SvgStation from '@/components/svg/SvgStation'

import { GetCurrentSchedule } from '@/utils/dateFormat'
import {
  getOwnerOrderDetailPage, getOwnerstationlevellist, getResourceCountByOrderId,
  getProductDetailPageByOrder
} from '@/api/order/productdetail'
import { toMoney } from '@/utils/wnumb_own'

export default {
  components: {
    SvgStation
  },
  mixins: [sysMixins],
  data () {
    return {
      scheduleIndex: 0,
      assets: [],
      stationDistribution: [],

      resourceListQuery: {
        orderId: null,
        pageNumber: 1,
        pageSize: 15,
        startDate: '',
        endDate: ''
      },
      total: 0,
      tableData: [],
      FixedColumn: [ // 显示地铁灯箱或者路面媒体
        { title: '资源编号', key: 'resourceCode' },
        { title: '所属站台', key: 'stationName' },
        { title: '站点等级', key: 'stationLevelName' },
        {
          title: '资源类型',
          render: (h, params) => {
            let showLable = params.row.resourcetypeName
            if (params.row.duration > 1) {
              showLable += ' (' + params.row.duration + '秒 | '
              showLable += (params.row.tduration / params.row.duration) + '次/天)'
            }

            if (params.row.side > 1) {
              showLable += ' (' + params.row.side + ' 面)'
            }

            return h('span', showLable)
          }
        },
        { title: '资源位置', key: 'positionName' },
        { title: '所属资产', key: 'assetName' },
        {
          title: '价格',
          render: (h, data) => {
            return h('span', toMoney(data.row.useprice))
          }
        }
      ],

      svgModal: {
        isShow: false,
        stationName: '',
        stationId: 0,
        startDate: '',
        endDate: '',
        svgShowType: 3 // 3订单详情资源展示
      }
    }
  },
  mounted () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      const selectSchedules = this.schedules[this.scheduleIndex]

      const queryModel = {
        orderId: this.orderId,
        startDate: selectSchedules.startDate,
        endDate: selectSchedules.endDate
      }
      getOwnerstationlevellist(queryModel).then(res => {
        this.assets = res
      })

      const queryModel2 = {
        orderId: this.orderId,
        schedules: JSON.stringify([{ startDate: selectSchedules.startDate, endDate: selectSchedules.endDate }])
      }
      getResourceCountByOrderId(queryModel2).then(res => {
        this.stationDistribution = res
      })

      // 开始获取资源清单
      this.resourceListQuery.orderId = this.orderId
      this.resourceListQuery.startDate = selectSchedules.startDate
      this.resourceListQuery.endDate = selectSchedules.endDate
      this.loadOrderDetailList()
    },
    loadOrderDetailList () {
      getOwnerOrderDetailPage(this.resourceListQuery).then(res => {
        this.tableData = res.list
        this.total = res.totalRow
      })
    },
    changePage (currentPage) {
      this.resourceListQuery.pageNumber = currentPage
      this.loadOrderDetailList()
    },
    getScheduleInfo (schedule) {
      return GetCurrentSchedule(schedule.startDate, schedule.endDate)
    },
    showStationSvg (station) {
      if (station.stationId) {
        this.svgModal.stationId = station.stationId
        this.svgModal.stationName = station.stationName

        const selectSchedules = this.schedules[this.scheduleIndex]
        this.svgModal.startDate = selectSchedules.startDate
        this.svgModal.endDate = selectSchedules.endDate

        this.svgModal.isShow = true
      }
    },
    svgOrThreeFinished () {
      if (this.svgModal.isShow) {
        this.$refs.svgStation.initShowBrand()
      }

      const schedule = JSON.stringify([{ startDate: this.schedules[this.scheduleIndex].startDate, endDate: this.schedules[this.scheduleIndex].endDate }])
      const resourceSearchData = {
        orderId: this.orderId,
        stationIds: this.svgModal.stationId,
        schedules: schedule,
        pageSize: 200,
        pageNumber: 1
      }

      getProductDetailPageByOrder(resourceSearchData).then(res => {
        this.$refs.svgStation.tagResource(res.list)
        this.$refs.svgStation.choseSpinShow()
      })
    },
    onViewOrderMaterial () {
      // 设置头部路由标签
      this.setLinkTagArray({ key: 'ownerOrderMaterial', value: '订单素材' })
      this.setLinkRouterMaps(['ownerOrderMaterial', () => {
        this.setShowRightBlock(true)
        this.setShowLeftBlock(true)
        this.setLeftComponent('LeftList')
        this.setBottomComponent('MaterialManage')
        this.setRightComponent('Record')
      }])
      this.setActivedTagName('ownerOrderMaterial')
    }
  },
  computed: {
    orderId () {
      return this.$store.state.ownerOrder.orderBean.id
    },
    schedules () {
      return this.$store.state.ownerOrder.orderBean.purchasedScheduleList
    }
  }
}
</script>
